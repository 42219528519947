import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import axios from "axios";
import React, { createRef, forwardRef, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Carousel from "./Carousel";
import CollapsibleSection from "./CollapsibleSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isMobile } from "react-device-detect";

const Section0 = forwardRef((props, ref) => {
    const { showActNowBtn, links, loop, showHeading, head, isFirst, id, cusMar } = props;
    return (
        <Box sx={
            { position: 'relative', maxHeight: 'calc(100vh - 160px)', minHeight: '570px', maxWidth: '1350px', margin: '0 auto' }
        } className="flex-acjc  home-sec">
            <Card id={id} sx={{
                width: '100%',
                height: '100%',
                maxWidth: '1050px',
                position: 'relative',
            }} elevation={0} className="sec1-card">
                {showHeading ? <Box sx={{
                    margin: '30px auto',
                    // paddingLeft: id ? '110px' : 0
                }}>
                    {
                        head.map((text, i) => {
                            return (
                                <div className="sec6-heading" style={{
                                    marginBottom: '30px'
                                }} key={i}>
                                    {text}
                                </div>
                            )
                        })
                    }
                </Box> : null}
                <CardMedia
                    id="sec1"
                    ref={ref}
                    className='hide--sm'
                    component={'video'}
                    sx={isFirst ? {

                        width: '100%',
                        height: 'auto',
                        margin: '0 auto',
                        objectFit: 'contain'
                    } : showHeading ? {
                        width: '75%',
                        margin: cusMar ? '-30px auto 0' : '0 auto'
                    } : {
                        mt: '-95px'
                    }}
                    useMap="#sec1"
                    // className={classes.media}
                    loop={loop || false}
                    image={links[1]}
                    muted
                    autoPlay
                />
                <Box sx={{

                    position: 'absolute',
                    top: '20%',
                    left: '4%',
                    width: '100%'
                }}  className="sec1-text--main">
                    <Box sx={{

                        maxWidth: '400px'
                    }} className="sec1-text-container">
                        <Box sx={{
                            margin: '20px 0px'
                        }}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '40px !important',
                                lineHeight: '36px',
                                display: 'flex',
                                alignItems: 'center'
                            }} variant='h1'>
                                BuddyNow AI
                            </Typography>
                        </Box>
                        <Box sx={{
                            margin: '20px 0px'
                        }} display='flex' alignItems={'flex-start'}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '35px',
                                lineHeight: '36px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#6c6c6c',
                                pt: '5px'
                            }}  variant='h1'>
                                Know your
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    padding: 1,
                                    width: '155px'
                                }}
                                alt="life insights"
                                src={'/landing/sec1_words.png'}
                            >
                            </Box>
                        </Box>
                        <Box sx={{
                            margin: '20px 0px 10px'
                        }} className="know-your">
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '17px',
                                lineHeight: '21px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'justify'
                            }}>
                                {/* 94% changed to 96% on 2025.03.02 */}
                                96% prediction accuracy reports with actionable insights powered by AI (Artificial Intelligence).
                            </Typography>
                        </Box>
                        <Box sx={{
                            margin: '10px 0px'
                        }}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '17px',
                                lineHeight: '21px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'justify'
                            }}>
                                Pre-empt yourself against potential issues and maximize opportunities.
                            </Typography>
                        </Box>
                        <Box sx={{
                            margin: '20px 0px'
                        }}>
                            <Link to={{
    pathname: "/insights",
    hash: "#top",
  }} style={{
    textDecoration: 'none'
  }}>
 <Button variant="contained" className={showActNowBtn ? 'make-visible act-now' : 'act-now'} sx={{
                                background: '#b99a38',
                                color: '#fff',
                                width: '300px',
                                height: '110px',
                                fontSize: '50px',
                                fontWeight: 'bold',
                                marginTop: '40px'
                            }}>
                                Act Now
                            </Button>
  </Link>


                        </Box>
                    </Box>
                </Box>


                <map name="sec1">
                    <area shape="rect" coords="300,520,700,800" alt="buddy now" href="/insights" />
                </map>
            </Card>
        </Box>

    )
})
const Section1 = forwardRef((props, ref) => {
    const { showActNowBtn, links, loop, showHeading, head, isFirst, id, cusMar, subLinks = [] } = props;

    return (
        <Card id={id} className="flex-acjc video-section  home-sec home-sec--bottomsec" sx={isFirst ? { position: 'relative', height: 'calc(100vh - 160px)', minHeight: '570px',maxWidth: '1350px', margin: '0 auto'  } : { position: 'relative', height: '100vh', p: 10, flexDirection: 'column', minHeight: '570px',maxWidth: '1350px', margin: '0 auto'  }} elevation={0}>
            {showHeading ? <Box sx={{
                margin: '30px auto',
                // paddingLeft: id ? '110px' : 0
            }} className='small-section'>
                { id == 'sec7' ?
                    <Box sx={{
                        width: '100%',
                        mb: 4,
                    }} className='show--sm'>
                        <div className="sec6-heading" style={{
                            width: '100%',
                            color: '#b99a38'
                        }}>
                            How does BuddyNow work?
                        </div>
                    </Box> : null
                }
                {
                    head.map((text, i) => {
                        return (
                            <div className="sec6-heading" style={{
                                marginBottom: '30px',
                                color: '#b99a38'
                            }} key={i}>
                                {text}
                            </div>
                        )
                    })
                }
            </Box> : null}
            { !isMobile ? <CardMedia
                id="sec1"
                ref={ref}
                className='hide--sm'
                component={showActNowBtn ? 'img' : 'video'}
                sx={isFirst ? {
                    width: '100%',
                    height: '100%',
                    margin: '0 auto',
                    transform: 'scale(1.2)',
                    objectFit: 'contain'
                } : showHeading ? {
                    width: '75%',
                    margin: cusMar ? '-30px auto 0' : '0 auto'
                } : {
                    mt: '-95px'
                }}
                useMap="#sec1"
                // className={classes.media}
                loop={loop || false}
                image={showActNowBtn ? links[0] : links[1]}
                muted
                playsinline
                autoPlay
            /> : null}
            <CardMedia
                playsinline
                id="sec1"
                ref={ref}
                className='show--sm'
                component={subLinks?.length == 1 ? 'img' : 'video'}
                sx={{
                    width: '75%',
                    display: 'none',
                    marginTop: '30px'
                }}
                useMap="#sec1"
                // className={classes.media}
                loop={loop || false}
                image={subLinks?.length == 1 ? subLinks[0] : subLinks[1]}
                muted
                controls
                poster={subLinks[2]}
                preload="auto"
            />
        </Card>
    )
})
const Section2 = forwardRef((props, ref) => {
    const { data, catagory, secId, explandLast } = props;
    const [panelKey, setPanelKey] = useState(explandLast ? explandLast : 'panel1')
    const handlePanelKey = (panel) => setPanelKey(panel);
    return (
        <Box sx={{
            flexGrow: 1,
            padding: '100px', minHeight: '570px',maxWidth: '1350px' , margin: '0 auto'
        }} className="full-height flex ac jcc sec2--container home-sec">
            <Grid container spacing={2}>

                <Grid item sm={12} md={4} className="f-column flex ac jcc">
                    <CollapsibleSection handlePanelKey={handlePanelKey} catagory={catagory} secId={secId} explandLast={explandLast} />
                </Grid>
                <Grid item sm={0} md={8} className="f-column flex ac jcc hide--sm" style={{
                        paddingLeft: '50px'
                }}>
                    <Card sx={{ position: 'relative', flexGrow: 1 }} elevation={0}>
                        <CardMedia
                            component={panelKey == 'panel1' ? 'img' : 'video'}
                            sx={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain'

                            }}
                            className='hide--sm'
                            image={data[panelKey]}
                            loop={data[panelKey]?.includes('sec3_3.webm')}
                            ref={data[panelKey]?.includes('sec3_3.webm') ? ref : null }
                            muted={data[panelKey]?.includes('sec3_3.webm')}
                            playsinline={data[panelKey]?.includes('sec3_3.webm')}
                            autoPlay={!data[panelKey]?.includes('sec3_3.webm')}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
})


const Footer = ({ history }) => {
    const handleClick = (link) => {
        window.scrollTo(0, 0)
        history.push(link)
    }
    return (
        <Box sx={{ flexGrow: 1, background: '#ede6cd', mt: '40px' }}>
            <Grid container spacing={2}  justifyContent='center'>
                <Grid item xs={3} justifyContent="center" alignContent={'center'} display="flex" className="hide--sm">
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Button href="https://www.tiktok.com/@buddynow.com" target="_blank">
                            <Avatar alt="TikTok" src="/landing/tiktok.svg" sx={{ mx: 1 }} />
                        </Button>
                        <Button href="https://www.instagram.com/buddynowai/" target="_blank">
                            <Avatar alt="Instagram" src="/landing/instagram.svg" sx={{ mx: 1 }} />
                        </Button>
                        {
                          /* Removed on 2025.03.02
                          <Button href="https://www.reddit.com/r/BuddyNow/" target="_blank">
                              <Avatar alt="Reddit" src="/landing/reddit.png" sx={{ mx: 1 }} />
                          </Button>
                          */
                        }
                        <Button href="https://www.youtube.com/@BuddyNow" target="_blank">
                            <Avatar alt="Youtube" src="/landing/youtube.png" />
                        </Button>

                    </Box>
                </Grid>
                <Grid item xs={3} className="hide--sm">
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: '#ede6cd' }}
                        subheader={<ListSubheader sx={{
                            background: '#ede6cd',
                            fontWeight: 700,
                            color: '#000'
                        }}>Services</ListSubheader>}
                    >
                        <ListItem sx={{
                            p: '8px !important',
                            paddingLeft: '16px !important'
                        }}>

                            <Link to="/insights#top" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,

                                color: '#000'
                            }}>
                                Insights
                            </Link>
                        </ListItem>
                        <ListItem>

                            <Link to="/cc#top" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,

                                color: '#000'
                            }}>
                             Couple Compatibility
                        </Link>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: '#ede6cd' }}
                        subheader={<ListSubheader sx={{
                            background: '#ede6cd',
                            fontWeight: 700,
                            color: '#000',
                        }}>Company</ListSubheader>}
                    >
                        <ListItem sx={{
                            p: '8px !important'
                        }}>
                            <Link to="/about#top" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,
                                color: '#000'
                            }} className="align-footer">
                                About Us
                            </Link>
                            {/* <ListItemText id="switch-list-label-wifi" primary="About Us" onClick={()=>handleClick('about')}/> */}
                        </ListItem>
                        <ListItem sx={{
                            p: '8px !important'
                        }}>
                            <Link to="/affiliate" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,
                                color: '#000'
                            }} className="align-footer">
                                Affiliate
                            </Link>
                            {/* <ListItemText id="switch-list-label-bluetooth" primary="Affiliate" onClick={()=>handleClick('affiliate')} /> */}
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={3} sm={4} xs={7}>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: '#ede6cd' }}
                        subheader={<ListSubheader sx={{
                            background: '#ede6cd',
                            fontWeight: 700,
                            color: '#000'
                        }}>Legal</ListSubheader>}
                    >
                        <ListItem sx={{
                            p: '8px !important'
                        }}>
                            {/* <ListItemText id="switch-list-label-wifi" primary="Term and Conditions" onClick={()=>handleClick('termsandconditions')} /> */}
                            <Link to="/termsandconditions#top" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,
                                color: '#000'
                            }} className="align-footer">
                                Terms and Conditions
                            </Link>

                        </ListItem>
                        <ListItem sx={{
                            p: '8px !important'
                        }}>
                            <Link to="/privacy#top" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,
                                color: '#000'
                            }} className="align-footer">
                                Privacy Policy
                            </Link>
                            {/* <ListItemText id="switch-list-label-bluetooth" primary="Privacy Policy" onClick={()=>handleClick('privacy')}  /> */}
                        </ListItem>
                        <ListItem sx={{
                            p: '8px !important'
                        }}>
                            <Link to="/faq#dp" style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                textDecoration: 'none',
                                fontSize: '14px',
                                p: 1,
                                color: '#000'
                            }} className="align-footer">
                                Data Protection
                            </Link>
                            {/* <ListItemText id="switch-list-label-bluetooth" primary="Data Protection" onClick={()=>handleClick('faq#data-protect')}  /> */}
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

const FaqSection = () => {
    return (
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', flexGrow: 1, minHeight: '570px',maxWidth: '1350px', margin: '0 auto' }} className=" flex ac jcc hide--sm">
            <Grid container spacing={2}>

                <Grid item xs={12} className="f-column flex ac jcc" sx={{
                    background: '#fff',
                    borderRadius: 2,
                    outline: 1,
                    border: '1px solid #ddd',
                    padding: 2,
                    '& .MuiAccordionSummary-expandIconWrapper > svg': {
                        background: '#b99a38',
                        background: '#DFC779',
                        borderRadius: '50%',
                        color: '#fff',
                        fontSize: 'x-large'
                    },
                    '& .MuiAccordionSummary-root': {
                        background: '#ede6cd'
                    },
                    '& .MuiAccordion-rounded': {
                        margin: '12px auto'
                    }
                }}>
                    <Box>
                        <Typography variant="h3" className="sec6-heading" sx={{
                            mb: 4
                        }}>
                            Frequently Asked Questions
                        </Typography>
                        <Accordion  defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><b>How is your data and privacy protected?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p>Keeping your privacy and data protected is BuddyNow's #1 priority. Below are several steps implemented, not limited to: </p>
                                    <ul>
                                        <li><b>Anonymity:</b> <br />No one at BuddyNow will contact you unless you have contacted us to do so. Your Personally Identifiable Information (PII) is never required to access Buddynow.com and/or to generate the reports unless you have selected the email option. Stripe may require your personal data, (i.e., name, address, credit/bank info) only for payment transaction purposes. BuddyNow does not store this data. </li>
                                        <br /><li><b>Ad Analytics:</b> <br />We do not use third-party vendors to track, optimize and serve ads based on your past activity on our website.</li>
                                        <br /><li><b>Data Analysis:</b> <br /> All analysis is generated by an AI machine learning algorithm. </li>
                                        <br /><li><b>Password Protection:</b> <br />You can create a passport to protect your report.  BuddyNow does not store your report password. </li>
                                        <br /><li><b>No Third-party Advertisers:</b> <br />BuddyNow does not allow third-party advertising companies to serve ads when you visit our site. We do not sell, distribute, or lease your personal data to third parties for monetary considerations. We will not send marketing emails to you.  </li>
                                        <br /><li><b>Data Storage:</b> <br />Reports are only stored for 7 days to allow users to re-download them in case of any issues. They are deleted after that. Quiz inputs and previously generated report data are only stored locally on a user's browser and not on our servers. To delete this info look in the section below. In addition, we do not collect or store any email IDs. </li>
                                    </ul></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography><b>How can BuddyNow benefit you?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p>BuddyNow's Behavorial Science Algorithm generates personalized AI-generated Reports that provide actionable insights across certain life categories  which you can use to navigate or pre-empt yourself against potential problems or maximize opportunities.</p>
                                    <p>To find out more about the AI Reports check out the <b>AI Reports</b> Section below.</p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography><b>Probable Daily Use Cases</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <p><b>Youtube Link:</b><a href="https://www.youtube.com/watch?v=Y8bs93r8DxM" target="_blank"> BuddyNow: Use Case - Single No More, Mental Health, Wealth Income </a></p>
                                <ul>
                                <li><b>Stay Healthy!</b>
                                    <p>Lily is worried about her dad’s health thus she buys him “Physical Health with Specified illnesses” Report which will show him the physical ailments that he might get during specific time periods.</p>
                                    <br />
                                    <p>Physical health ailments can range from a simple running nose to something as serious as cancer/ heart problems and every possible health condition in between.</p>
                                </li>
                                <li><b>Tired of being Single?</b>
                                    <p>Bob has spent hundreds of dollars on dating sites and is desperately looking for a date. Bob buys the 'Single-No-More with Characteristics' Report which tells him both when he has a high chance of meeting potential romantic partners & their characteristics</p>

                                </li>
                                <li><b>Is this person right for me?</b>
                                    <p>Mary likes both John and Max but is undecided on who to date. Thus, Mary buys 2 Couple Compatibility Reports to generate her compatibility scores with John and Max.</p>
                                    <p>Mary uses the reports as a guide to make a more informed decision on her potential relationship choice</p>
                                </li>
                                <br /><li><b>All Stressed Out</b>
                                    <p>Alice is stressed out due to work and constant worries about her kids mood swings. She feels low from time to time, and she never knows when she is going to feel that way until it happens. Alice buys the 'Mental Health' Report which tells her when she is likely to face mental health problems. She is now able to take pre-emptive action beforehand and avoids these problems!</p>
                                </li>
                                <br /><li><b>Show me the Money</b> <p>Sandy has tried her hand at investing but constantly wonders why she has not been able to make profits. Sandy purchases both the 'Wealth Income' and 'Wealth Loss' Reports. They advise her on the best and worst times to invest. Using our AI as a guide, Sandy is able maximize her investment ability & increase her wealth!</p>
                                </li>
                                </ul>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography><b>Is it important to answer the Quiz accurately?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <p>Yes! Please answer all questions as accurately as possible, to get the most accurate AI Report result. It is like a GPS; you give it the right input to get an accurate destination.</p>
                                <p>On the Personal Insight report, you receive a grade for the quality of quiz inputs you have provided. This grade is found near the top of your personalized AI Report!</p>
                                <ul>
                                <li><b>Grade A:</b> Higher Probability of AI Report Accuracy  </li>
                                <li><b>Grade B:</b> Mid-High Probability of AI Report Accuracy </li>
                                <li><b>Grade C:</b> Low Probability of AI Report Accuracy</li>
                                </ul>
                                <br />
                                <p>On the Couple Compatibility report, you receive a range of compatibility scores between yourself and your prospective/current partner. These scores are based on seven major relationship categories.</p>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><b>Does BuddyNow store your Quiz answers?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p>No! BuddyNow does not store any quiz answers from users. </p>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography><b>What is the AI report?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <p><b>The Personal Insight report</b> provides an insight of your potential across certain life categories over the present and next 12 months.</p>
                                <p>The report is like having a Life 'GPS' to pre-empt yourself against potential problems or maximize opportunities.</p>
                                <p><b>The Couple Compatibility report</b> provides you with a range of compatibility scores between yourself and your prospective/current partner. These scores are generated by processing your quiz inputs through the BuddyNow Behavioral Science Algorithm.</p>
                                <p>This report can help you to make more informed choices in your relationships.</p>
                                <p>All reports are unbiased as they are generated by the BuddyNow AI based on the answers you provided to the quiz.</p>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><b>How is your data and privacy protected?</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p>Keeping your privacy and data protected is BuddyNow's #1 priority. Below are several steps implemented, not limited to: </p>
                                    <ul>
                                        <li><b>Anonymity:</b> <br />No one at BuddyNow will contact you unless you have contacted us to do so. Your Personally Identifiable Information (PII) is never required to access Buddynow.com and/or to generate the reports unless you have selected the email option. Stripe may require your personal data, (i.e., name, address, credit/bank info) only for payment transaction purposes. BuddyNow does not store this data. </li>
                                        <br /><li><b>Ad Analytics:</b> <br />We do not use third-party vendors to track, optimize and serve ads based on your past activity on our website.</li>
                                        <br /><li><b>Data Analysis:</b> <br /> All analysis is generated by an AI machine learning algorithm. </li>
                                        <br /><li><b>Password Protection:</b> <br />You can create a passport to protect your report.  BuddyNow does not store your report password. </li>
                                        <br /><li><b>No Third-party Advertisers:</b> <br />BuddyNow does not allow third-party advertising companies to serve ads when you visit our site. We do not sell, distribute, or lease your personal data to third parties for monetary considerations. We will not send marketing emails to you.  </li>
                                        <br /><li><b>Data Storage:</b> <br />Reports are only stored for 7 days to allow users to re-download them in case of any issues. They are deleted after that. Quiz inputs and previously generated report data are only stored locally on a user's browser and not on our servers. To delete this info look in the section below. In addition, we do not collect or store any email IDs. </li>
                                    </ul></Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const HomePage = ({ history }) => {

    const [showActNowBtn, setShowActNowBtn] = useState(false);
    const [articles, setSetArticles] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const ref1 = useRef(null);
    const ref3 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);

    const handleScroll = (event) => {
        if (event.srcElement.scrollTop > 1300 && event.srcElement.scrollTop < 1900) {
            ref3?.current?.play && ref3.current.play();
        }
        if (event.srcElement.scrollTop > 5650 && event.srcElement.scrollTop < 5750) {
            ref7.current.play && ref7.current.play();
        }
        if (event.srcElement.scrollTop > 6400 && event.srcElement.scrollTop < 7000) {
            ref8.current.play && ref8.current.play();
        }
        // itemTranslate = Math.min(0, scrollTop/3 - 60);
    }

    useEffect(() => {
        document.getElementById('sec1').addEventListener('ended', handleActNowBtn, false);
        document.title = "BuddyNow - Home";
        getArticles();
        getTestimonials();
        window.removeEventListener('scroll', handleScroll, true);
        window.addEventListener('scroll', handleScroll, true);
        const testimonialsId = setInterval(getTestimonials, 1000 * 60 * 5);
        const articlesId = setInterval(getArticles, 1000 * 60 * 5);
        if (window.location.hash) {
            document.querySelector(window.location.hash).scrollIntoView({
              behavior: 'instant'
            });
          }

        // if(isMobile) {
        //     alert('For the best user experience, our recommendation is to access our website via desktop or laptop.')
        // }
        return () => {
            clearInterval(testimonialsId);
            clearInterval(articlesId);
            return window.removeEventListener('scroll', handleScroll, true)
        };
    }, []);

    // useEffect(()=> {
    //     ref1.current.play();
    // }, [testimonials])

    const getTestimonials = () => {
        axios
            .get(`${process.env.REACT_APP_SERVER}/buddy/testimonials`)
            .then(({ data }) => {
                setTestimonials([...data])
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getArticles = () => {
        axios
            .get(`${process.env.REACT_APP_SERVER}/buddy/articles`)
            .then(({ data }) => {
                setSetArticles([...data])
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleActNowBtn = () => setShowActNowBtn(!showActNowBtn)

    const sec6 = [
        'How does BuddyNow work?',
        'Select. Act. Explore.',
        'Anywhere. Anytime. Any Connected Devices.'
    ]
    const sec7 = [
        'Step 1',
        'Select your personalized AI-generated report.',
    ]
    const sec8 = [
        "",
        'Step 2',
        'Act now',
    ]
    const sec9 = [
        "",
        'Step 3',
    ]
    return (
        <Box className="landing-page" sx={{
            background: '#fff',
            minHeight: 'calc(100vh)',overflow: 'hidden',
            // mt: 20,
            // top: '160px',
            // position: 'relative'
        }}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Box id="top" className="top-cantainer">

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section0 showActNowBtn={showActNowBtn} links={["", "landing/sec1.mp4"]} ref={ref1} isFirst={true} id='section1' />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section2 data={
                            {
                                'panel1': 'landing/sec2_1.jpg',
                                'panel2': 'landing/sec2_2.webm',
                                'panel3': 'landing/sec2_3.webm'
                            }
                        } catagory={{
                            heading: 'Know your Health',
                            subHeading: 'Discover health-related issues early to take pre-emptive action.',
                            list: [
                                {
                                    title: 'Health Reports',
                                    key: 'panel1',
                                    subList: [
                                        'Physical Health',
                                        `Physical Health
                            (specified illnesses)`,
                                        'Physical Harm',
                                        'Accident',
                                        'Mental Health'
                                    ]
                                }, {
                                    title: 'Be Brave',
                                    key: 'panel2',
                                    subList: [
                                        'Bravely face any potential health problems'
                                    ]
                                }, {
                                    title: 'Take Action',
                                    key: 'panel3',
                                    subList: [
                                        'Take pre-emptive action - exercise, have a healthy diet, stay alert, consult health experts'
                                    ]
                                }
                            ]
                        }} secId={'green'} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section2 data={
                            {
                                'panel1': 'landing/sec3_1.png',
                                'panel2': 'landing/sec3_2.webm',
                                'panel3': 'landing/sec3_3.webm',
                            }
                        } catagory={{
                            heading: 'Know your Romantic Relationships',
                            subHeading: "Find love and live a happier life. Don't miss out on your love life!",
                            list: [
                                {
                                    title: 'Relationship Reports',
                                    key: 'panel1',
                                    subList: [
                                        'Single No More',
                                        'Single No More (characteristics)',
                                        'Good Relationship',
                                        'Bad Relationship',
                                        'Couple Compatibility'
                                    ]
                                }, {
                                    title: 'Have a Fulfilling and Happy Relationship',
                                    key: 'panel2',
                                    subList: [
                                        'Maximize your time with the ones who matter'
                                    ]
                                }, {
                                    title: 'Couple Compatibitlity',
                                    key: 'panel3',
                                    subList: [
                                        'Know the 7 key metrics of relationship compatiblity',
                                        'To get a deeper understanding of how your relationship with a particular person is likely to pan out.'
                                    ]
                                }
                            ]
                        }} secId={'pink'} explandLast={'panel3'} ref={ref3}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section2 data={
                            {
                                'panel1': 'landing/sec4_1.jpg',
                                'panel2': 'landing/sec4_2.webm'
                            }
                        } catagory={{
                            heading: 'Know your Career',
                            subHeading: "Grow your business and career. Avoid career pitfalls.",
                            list: [
                                {
                                    title: 'Career Reports',
                                    key: 'panel1',
                                    subList: [
                                        'Career Success',
                                        'Challenges in Career',
                                    ]
                                }, {
                                    title: 'Skillfully Navigate Your Career',
                                    key: 'panel2',
                                    subList: [
                                        'Navigate your career successfully and avoid pitfalls'
                                    ]
                                }
                            ]
                        }} secId={'yellow'} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section2 data={
                            {
                                'panel1': 'landing/sec5_1.jpg',
                                'panel2': 'landing/sec5_2.webm'
                            }
                        } catagory={{
                            heading: 'Know your Wealth',
                            subHeading: "Achieve financial freedom. Avoid wealth losses.",
                            list: [
                                {
                                    title: 'Wealth Reports',
                                    key: 'panel1',
                                    subList: [
                                        'Wealth Income',
                                        'Wealth Loss',
                                    ]
                                }, {
                                    title: 'Grow Your Wealth',
                                    key: 'panel2',
                                    subList: [
                                        'Maximize wealth generation and mitigate losses'
                                    ]
                                }
                            ]
                        }} secId={'lgreen'}  explandLast={'panel2'}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section1 showActNowBtn={false} links={["", "landing/sec7.webm"]} subLinks={["landing/sec7_mobile.png"]} ref={ref7} showHeading={true} head={sec7} cusMar={true} id={'sec7'} loop={true}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section1 showActNowBtn={false} links={["", "landing/sec8.webm"]} subLinks={['landing/sec8_mobile.png']} ref={ref8} showHeading={true} head={sec8} cusMar={true} loop={true}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Section1 showActNowBtn={false} links={["", "landing/sec9.mp4"]} subLinks={["", "landing/sec9_mobile.mp4", 'landing/sec9_mobile.jpg']} showHeading={true} head={sec9} loop={true} cusMar={true} />
                    </Box>
                </Grid>
            </Grid>
            {
              /* Removed reddit links 2025.03.02
              <Carousel list={testimonials} heading={'Check out our results!'} subHeading={'Among our customers’ <a href="https://www.reddit.com/r/BuddyNow/comments/uk7s12/just_curious_are_your_reports_accurate/" target="_blank">testimonials</a>'} />
              <Carousel list={articles} heading={'Technology for Tomorrow’s Solutions'} subHeading={'<a href="https://www.reddit.com/r/BuddyNow/" target="_blank">Learn more</a> about the technology that powers BuddyNow'} />
              */
            }
            <Carousel list={testimonials} heading={'Check out our results!'} subHeading={'Among our customers’ testimonials'} />
            <Carousel list={articles} heading={'Technology for Tomorrow’s Solutions'} subHeading={'Learn more about the technology that powers BuddyNow'} />
            <FaqSection />
            <Footer history={history} />
        </Box>
    );
}

export default withRouter(HomePage);
