import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";

const Carousel = ({ list, heading, subHeading, limit, isWhatsNew }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: limit || 3,
        slidesToScroll: 3,
        autoplaySpeed: 15000,
        autoplay: true,
        slidesPerRow: 1,
        swipeToSlide: true,
        swipe: true,
        draggable: true,
        accessibility: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        rows: 1
    };
    return (
        <Box sx={isWhatsNew ? {} : {
            maxWidth: '1200px',
            margin: '10% auto',
        }} className='hide--sm'>
            <Box sx={{
                // '.slick-dots li': {
                //     display: 'none',
                // },
               '.slick-dots li:nth-of-type(3n+1)': {
                    display: 'inline-block',
                },
                '.slick-dots li button:before': {
                    fontSize: 20
                }
            }}>
                <Typography variant='subtitle1' className='heading sec6-heading' sx={{
                    fontSize: '30px'
                }}>{heading}</Typography>
                <Typography variant='body1' className='sub-heading  sec6-heading' dangerouslySetInnerHTML={{ __html: subHeading }} ></Typography>
                <Slider {...settings}>
                    {
                        list.map(item => {
                            return (
                                <Card
                                    key={item.id}
                                    sx={{
                                        maxWidth: isWhatsNew ? '220px' : 300,
                                        minHeight: isWhatsNew ? '250px' : item.avatar_url ? '550px' : '320px',
                                        margin:  isWhatsNew ? 1 :'20px 30px',
                                        position: 'relative',
                                        '& .MuiPaper-root': {
                                            height:  isWhatsNew ? '250px' :'480px'
                                        }
                                    }}
                                >
                                    {item.avatar_url ? <CardMedia
                                        component="img"
                                        height="140"
                                        image={`landing/${item.avatar_url}`}
                                        sx={{
                                            margin: '20px auto',
                                            objectFit: 'contain',
                                            width: '80px',
                                            height: 'auto'
                                        }}
                                        alt={item.title}
                                    /> : null}
                                    <CardContent >
                                        <Typography gutterBottom variant="body2" component="div" sx={{
                                            textAlign: item.avatar_url ? 'center' : 'left',
                                            fontSize: item.avatar_url ? '12px' : '16px'
                                        }}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{
                                            fontSize: '16px',
                                            mt: 3,
                                        }}>
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                    {item.url ? <CardActions>
                                        {/* 2025.03.03 Changed Read More to Know More */}
                                        {isWhatsNew ? <Button size="small" sx={{m: 1}} href={item.url} target="_blank" className="read-more" variant="outlined">Learn More</Button> : <Button size="small" href={item.url} target="_blank" className="read-more">Know More</Button>}
                                    </CardActions> : null}
                                </Card>
                            )
                        })
                    }

                </Slider>
            </Box>

        </Box>
    )
}

export default Carousel
